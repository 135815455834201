
























import Vue, { PropType } from "vue";

export default Vue.extend({
    name: "BaSelect",
    props: {
        label: { type: String, default: "" },
        placeholder: { type: String, default: "Select" },
        required: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
        options: { type: Array as PropType<any[]>, required: true },
        selected: { type: Array as PropType<any[]>, required: true },
        value: { type: String, required: true, default: "" },
    },
    data() {
        return {
            showOptions: false,
        };
    },
    computed: {
        selectedOption(): string {
            const selectedOption = this.options.find((option: any) => option.key === this.value);
            if (selectedOption) {
                return selectedOption?.value as string;
            }
            return this.placeholder;
        },
        availableOptions(): any {
            return this.options.filter((option) => {
                return !this.selected.includes(option.key);
            });
        },
    },
    methods: {
        selectOption(option: string) {
            this.$emit("input", option);
            this.showOptions = false;
        },
    },
});
