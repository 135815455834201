






import Vue from "vue";

export default Vue.extend({
    name: "BaseDropdown",
    props: {
        show: { type: Boolean, required: true, default: false },
        top: { type: String, required: false },
        right: { type: String, required: false },
        left: { type: String, required: false },
        width: { type: String, required: false, default: "120px" },
        height: { type: String, required: false, default: "auto" },
    },
    mounted() {
        window.addEventListener("click", this.handleOnClickOutside);
    },
    destroyed() {
        window.removeEventListener("click", this.handleOnClickOutside);
    },
    methods: {
        handleOnClickOutside(event: Event) {
            const classList = (event.target as HTMLElement)?.classList;
            if (!classList.contains("base-dropdown__toggler")) {
                const classListArray = Array.from(classList);
                if (classListArray && classListArray.length > 0) {
                    classListArray.forEach((classItem) => {
                        if (classItem.includes("base-dropdown")) {
                            // allow it
                        } else {
                            this.$emit("outside-clicked");
                        }
                    });
                } else {
                    this.$emit("outside-clicked");
                }
            }
        },
    },
});
