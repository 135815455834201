<template>
    <li
        class="m-0 base-dropdown__item d-flex align-items-center w-100"
        :class="{ 'base-dropdown__item--logout': text === 'Log Out', 'base-dropdown__item--alternate': alternateBackground }"
        :style="{ fontWeight, padding }">
        <span v-if="icon" class="base-dropdown__icon d-flex justify-content-center align-items-center">
            <ba-base-icon :name="icon" height="auto" width="auto" />
        </span>
        {{ text }}
    </li>
</template>

<script>
export default {
    name: "BaseDropdownItem",
    props: {
        icon: { type: String, required: false },
        text: { type: String, required: true },
        fontWeight: { type: String, required: false, default: "400" },
        alternateBackground: { type: Boolean, required: false, default: false },
        padding: { type: String, required: false, default: "16px 20px" },
    },
};
</script>

<style lang="scss" scoped>
.base-dropdown__icon {
    min-width: 20px;
    min-height: 20px;
}
.base-dropdown__item {
    @include typography(14px, 400, 20px);
    gap: 10px;
    // padding: 16px 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: $black-90;

    &--alternate {
        &:nth-child(even) {
            background-color: $gray-20;
        }
    }

    &:hover {
        background-color: rgba(120, 118, 118, 0.05);
    }

    &--logout {
        color: #db6345;
    }
}
</style>
