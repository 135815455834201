import { render, staticRenderFns } from "./BaPhoneField.vue?vue&type=template&id=591936cd&scoped=true&"
import script from "./BaPhoneField.vue?vue&type=script&lang=ts&"
export * from "./BaPhoneField.vue?vue&type=script&lang=ts&"
import style0 from "./BaPhoneField.vue?vue&type=style&index=0&id=591936cd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "591936cd",
  null
  
)

export default component.exports