


















import Vue from "vue";
import { SETTINGS } from "@/app/routes/endpoints";

export default Vue.extend({
  props: {
      main: {
          type: String
      },
      subText: {
          type: String
      }
  },
    data() {
        return {
            SETTINGS,
        };
    },
    methods: {
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
    },
});
