














import Vue from "vue";

export default Vue.extend({
    props: {
        text: { type: String, required: false, default: "" },
        variant: { type: String, required: false, default: "" },
        size: { type: String, required: false, default: "default" },
    },
    data(): { colorMappings: { [key: string]: string } } {
        return {
            colorMappings: {
                "Admin": "bg-blue-10 text-blue-90",
                "Certifier": "bg-green-10 text-green-70",
                "Reviewer": "bg-yellow-10 text-yellow-150",
                "Profile-Only": "bg-red-10 text-red-70",
                "Pending": "bg-yellow-10 text-yellow-150",
                "ReviewInProgress": "bg-blue-10 text-blue-90",
                "Reviewed": "bg-yellow-10 text-yellow-150",
                "CertificationIssued": "bg-black-90 text-gray-10",
                "CertificationDenied": "bg-red-10 text-red-70",
            },
        };
    },
    computed: {
        badgeColors(): string {
            if (this.text) return this.colorMappings[this.text];
            return "";
        },
    },
});
