











































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { SETTINGS, AUTH } from "@/app/routes/endpoints";
import ChangePasswordModal from "@/app/components/modals/ChangePasswordModal.vue";

export default Vue.extend({
    components: {
        ChangePasswordModal,
    },
    data() {
        return {
            showAvatarOptions: false,
            showMyRoles: false,
        };
    },
    computed: {
        ...mapGetters({
            getUserData: "auth/getUserData",
        }),
        ...mapGetters({ profileData: "auth/getUserData" }),
        isMenuAccessible(): boolean {
            return this.profileData?.roles?.includes("Admin") || this.profileData?.roles?.includes("ReportCreator");
        },
        avatarOptions(): Array<{ label: string; icon: string; action: () => void }> {
            const val = [
                { label: "My Profile & Signature", icon: "personal-settings", action: () => this.$router.push({ name: SETTINGS.PROFILE.NAME }) },
                { label: "Change Password", icon: "change-password", action: () => this.$bvModal.show("modal-change-password") },
                {
                    label: "Log Out",
                    icon: "log-out",
                    action: () => {
                        localStorage.clear();
                        this.$router.push({ name: AUTH.LOGIN.NAME });
                    },
                },
            ];
            if (this.getUserData.roles.includes("Admin")) {
                val.splice(
                    1,
                    0,
                    { label: "Organization Profile", icon: "company-profile", action: () => this.$router.push({ name: SETTINGS.BUSINESS.NAME }) },
                    { label: "Users, roles & profiles", icon: "users", action: () => this.$router.push({ name: SETTINGS.USER_ACCESS.NAME }) },
                );
            }

            return val;
        },
    },
    methods: {
        ...mapActions({ fetchFile: "file/fetchFile" }),
        goHome() {
            this.$router.push("/reports");
        },
        openMyRoles() {
            if (this.showAvatarOptions) this.showAvatarOptions = false;
            this.showMyRoles = !this.showMyRoles;
        },
        openAvatarOptions() {
            if (this.showMyRoles) this.showMyRoles = false;
            this.showAvatarOptions = !this.showAvatarOptions;
        },
    },
});
