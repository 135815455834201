






























import Vue from "vue";
import { SETTINGS } from "@/app/routes/endpoints";
import { mapGetters } from "vuex";

export default Vue.extend({
    data() {
        return {
            SETTINGS,
        };
    },
    computed: {
        ...mapGetters({
            profileData: "auth/getUserData",
        }),
    },
    methods: {
        goTo({ name }: { name: string }) {
            this.$router.push({ name });
        },
    },
});
