






















import Vue from "vue";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        "id": {
            type: [Number, String],
            required: true,
        },
        "size": { type: String, default: "lg" },
        "custom-content-class": String,
        "title": String,
        "description": String,
        "closeBtn": Boolean,
        "isIconRequired": { type: Boolean, required: false, default: false },
        "hasFooter": { type: Boolean, required: false, default: true },
        "height": { type: String, required: false, default: "500px" },
        "padding": { type: String, required: false, default: "24px 32px" },
        "icon": String,
    },
    methods: {
        closeModal(): void {
            this.$bvModal.hide(`modal-${this.id}`);
            this.$emit("hide");
        },
    },
});
