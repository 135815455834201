
















import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
    props: {
        hasPadding: { type: Boolean, required: false, default: true },
        hasBackground: { type: Boolean, required: false, default: true },
        showOnline: { type: Boolean, required: false, default: true },
        showName: { type: Boolean, required: false, default: true },
        showEmail: { type: Boolean, required: false, default: true },
        userData: { type: Object, required: true },
        nameWeight: { type: String, required: false, default: "500" },
        gap: { type: String, required: false, default: "10px" },
    },
    computed: {
        showText(): boolean {
            return this.showName && this.showEmail;
        },
        ...mapGetters({
            files: "file/getFiles",
        }),
        profileImageId(): string {
            const { profileImgId } = this.userData;
            return profileImgId;
        },
        profileImageUrl(): string {
            const imageObj = this.files[this.profileImageId];
            return (imageObj && imageObj.presignedUrl) || null;
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        imageOrAvatar(): any {
            return this.profileImageUrl ? { src: this.profileImageUrl } : { icon: "people-fill" };
        },
        firstName(): string {
            if (this.userData.fullName) return this.userData.fullName.split(" ")[0] ?? "";
            return this.userData.firstName;
        },
        lastName(): string {
            if (this.userData.fullName) return this.userData.fullName.split(" ")[1] ?? "";
            return this.userData.lastName;
        },
    },
    async mounted() {
        if (this.profileImageId) {
            await this.getFileUrl(this.profileImageId);
        }
    },
    methods: {
        ...mapActions({
            fetchFile: "file/fetchFile",
        }),
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", { title: "Unable to fetch file!", variant: "danger", solid: true });
            }
        },
    },
});
