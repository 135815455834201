







import Vue from "vue";

export default Vue.extend({
    props: {
        title: { type: String, required: true },
        hasSlot: { type: Boolean, required: false, default: false },
    },
});
