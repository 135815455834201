





















import Vue from "vue";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        label: { type: String, required: true },
        placeholder: { type: String },
        type: { type: String, default: "text" },
        tile: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        classText: { type: String, required: false, default: "transparent" },
    },
    methods: {
        isNumber(evt: any): boolean | void {
            // eslint-disable-next-line no-param-reassign
            evt = evt || window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }

            return false;
        },
    },
});
