











import Vue from "vue";

interface Refs {
    [key: string]: HTMLElement[];
}

export default Vue.extend({
    props: {
        tabs: { type: Array, required: true },
        activeTab: { type: String, required: true },
    },
    data() {
        return {
            activeLineWidth: 0,
            activeLineOffset: 0,
            newTab: "",
        };
    },
    watch: {
        activeTab(newActiveTab) {
            if (this.newTab === newActiveTab) return;
            this.moveActiveLine(newActiveTab);
            this.newTab = newActiveTab;
        },
    },
    mounted() {
        this.moveActiveLine(this.activeTab);
    },

    methods: {
        setActiveTab(tab: string) {
            this.$emit("set-active-tab", tab);
            this.moveActiveLine(tab);
            this.newTab = tab;
        },
        moveActiveLine(newValue: string) {
            if (!this.activeTab) return;
            if (!this.$refs || !this.$refs[newValue] || !(this.$refs as Refs)[newValue][0]) return;
            const element = (this.$refs as Refs)[newValue][0] as HTMLButtonElement;

            const innerText = element.innerText.trim();
            if (innerText === "Principles") {
                this.activeLineWidth = 75;
                this.activeLineOffset = 0;
            } else if (innerText === "Sections") {
                this.activeLineWidth = 66;
                this.activeLineOffset = 99;
            } else if (innerText === "Certification") {
                this.activeLineWidth = 95;
                this.activeLineOffset = 0;
            } else if (innerText === "Fines & Penalties") {
                this.activeLineWidth = 130;
                this.activeLineOffset = 119;
            } else {
                this.activeLineWidth = element.clientWidth;
                this.activeLineOffset = element.offsetLeft;
            }
        },
    },
});
