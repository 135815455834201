























import Vue from "vue";
import { HOME } from "@/app/routes/endpoints";

export default Vue.extend({
  name: "BaPageHeader",
  props: {
	title: {
	  type: String,
	  required: false,
	},
	description: {
	  type: String,
	  required: false,
	}
  },
  methods: {
        goHome() {
            this.$router.push({ name: HOME.INDEX.NAME });
        },
    },
});
