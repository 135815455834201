













































































import Vue from "vue";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import countryCodes from "../assets/providers/country-codes";

export default Vue.extend({
    inheritAttrs: true,
    props: {
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
        },
        tile: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        classText: {
            type: String,
            required: false,
            default: "transparent",
        },
        isCountryRequired: {
            type: Boolean,
            default: true,
            required: false
        },
    },
    data() {
        return {
            countryCodes,
            selected: countryCodes[153],
        };
    },
    methods: {
        isNumber(evt: any): boolean | void {
            // eslint-disable-next-line no-param-reassign
            evt = evt || window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }

            return false;
        },
    },
});
